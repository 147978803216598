<template>
  <div
    class="part-component section-pattern pattern absolute"
    :class="`pattern-${align}`"
  >
    <img
      :src="image"
      class="parallax-item parallax-relative"
      :data-depth="parallaxDepth * 0.8"
      ref="image"
    />
  </div>
</template>

<script>
import { partComponent } from "@/mixins/core.js";

export default {
  name: "PatternOverlay",
  components: {},
  mixins: [partComponent],
  props: {
    image: {
      type: String,
    },
    show: {
      type: Boolean,
    },
    parallaxDepth: {
      type: Number,
      default: 1.6,
    },
    align: {
      type: String,
      default: "left",
    },
  },
  data() {
    return {};
  },
  computed: {
    direction() {
      return this.align == "left" ? "-=10%" : "+=10%";
    },
  },
  methods: {
    createTimeline() {
      const { image } = this.$refs;

      const tl = new TimelineMax({
        paused: true,
      });

      tl.from(
        image,
        1.2,
        {
          ease: Power1.easeInOut,
          autoAlpha: 0,
          x: this.direction,
        },
        0
      );

      this.timeline = tl;
    },
  },
};
</script>

<style scoped>
.section-pattern {
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  height: 100%;
}
.section-pattern img {
  display: block;
  height: 100%;
}
.pattern.pattern-right {
  right: -15%;
}
.pattern.pattern-left {
  left: -15%;
}
</style>
