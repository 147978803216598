<template>
  <div :id="viewCSSId" :class="viewCSSClasses" ref="scene">
    <div class="section-foreground container">
      <div class="rows layout-column">
        <div class="layout-row row-1 layout-align-center-center">
          <div
            class="layout-column col-1 col-padding layout-align-center-end flex"
          >
            <section-title
              :partNo="part.id"
              :partTitle="part.title"
              data-depth="2.5"
              class="text-right parallax-item parallax-relative"
              :show="showTitle"
            ></section-title>
          </div>
          <div
            class="
              layout-column
              col-padding col-2
              layout-align-center-start
              flex
            "
          >
            <lead
              :show="showLead"
              class="parallax-item parallax-relative"
              data-depth="2"
              >{{ data.meta.description }}</lead
            >
          </div>
        </div>
        <div class="layout-row row-2 layout-align-center-start">
          <timeline
            :decal="data.images.decal"
            :ink="data.images.inkWide"
            :show="showDiagram"
            :data="data.timelines"
          ></timeline>
        </div>
      </div>
    </div>
    <div class="container fluid absolute z-index-3 no-select container-decal">
      <decal
        class
        :show="showDecal"
        :image="data.images.hornbills"
        :parallaxDepth="2.5"
        :customTimeline="timelines.timelineDecal"
      ></decal>
    </div>

    <background-image-full
      :image="data.images.background"
      :show="showBackground"
      :imageCss="{ objectPosition: 'bottom center' }"
      :mask="false"
      :timescaleReverse="2.5"
      :timescale="1.17"
    ></background-image-full>
    <div id="container-pattern" class="fixed fluid container z-index-0">
      <pattern-overlay
        :show="showPattern"
        :image="data.images.pattern2"
        id="pattern2"
      ></pattern-overlay>
    </div>
  </div>
</template>

<script>
import { chapterPart } from "@/mixins/core.js";

import SectionTitle from "@/components/layout/SectionTitle.vue";
import Lead from "@/components/ui/Lead.vue";
import Timeline from "@/components/ui/Timeline.vue";
import Decal from "@/components/ui/Decal.vue";
import PatternOverlay from "@/components/ui/PatternOverlay.vue";
import BackgroundImageFull from "@/components/ui/BackgroundImageFull.vue";

export default {
  name: "Chapter-4-2",
  mixins: [chapterPart],
  components: {
    SectionTitle,
    Lead,
    Timeline,
    Decal,
    PatternOverlay,
    BackgroundImageFull,
  },
  data() {
    return {
      doParallax: true,
      itemsToShow: [
        ["showDelay", 0],
        ["showBackground", 1400],
        ["showTitle", 600],
        ["showDiagram", 200],
        ["showDecal", 100],
        ["showPattern", 0],
        ["showLead", 1100],
      ],
      timelines: {
        timelineDecal: {
          duration: 1,
          animations: {
            ease: "Power1.easeInOut",
            autoAlpha: 0,
            x: "-=10%",
            y: "+=50",
            scale: "0.9",
            transformOrigin: "bottom left",
          },
        },
      },
    };
  },
  computed: {},
};
</script>

<style scoped>
.row-1 {
  padding-bottom: 0.5%;
}
.col-1 {
  max-width: 45.5%;
}
.col-2 {
  padding-left: 0;
}
/deep/ .lead {
  max-width: 640px;
}
.section-title {
  max-width: 40rem !important;
}
.section-decal {
  margin: 0;
}
.section-background {
  opacity: 0.15;
}

.container-decal {
  top: auto;
  margin: 0;
  right: auto;
  width: auto;
  height: auto;
}

.section-decal {
  margin-bottom: -1%;
  margin-left: -4%;
  max-width: 50vw;
  width: 460px;
}

.landscape .section-decal {
  max-width: 33vw;
}

/deep/ .bird img {
  width: 90%;
}

#pattern2 {
  left: -22%;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/
@media screen and (min-width: 1024px) {
  /deep/ .slide-text {
    max-width: 18em;
  }
}
@media screen and (max-width: 1600px) {
}
@media screen and (max-width: 1440px) {
  /deep/ .lead {
    max-width: 37em;
  }
  /deep/ .bird {
    margin: -1.2em 0 0 0.4em;
  }
  .row-2 {
    padding-bottom: 0.5rem;
  }
}
@media screen and (max-width: 1200px) {
  .section-title {
    max-width: 4.5em !important;
  }
  .col-2 {
    justify-content: center;
  }
  /deep/ .lead {
    max-width: 30em;
  }
  .col-1 {
    max-width: 44%;
  }
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 1023px),
  screen and (min-width: 992px) and (max-width: 1024px) and (orientation: portrait) {
  .row-1 {
    flex-direction: column;
  }
  .row-1 > * {
    max-width: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: var(--col-padding);
  }
  .section-title {
    max-width: 100% !important;
  }
  .part-lead {
    margin-bottom: 0.7rem;
    margin-top: 0;
  }
}

@media screen and (max-width: 991px) {
  .section-title {
    max-width: 11em !important;
  }
  .section-ink {
    top: 5% !important;
  }
}
@media screen and (max-width: 767px) {
  /deep/ .bird {
    margin: 0.3em 0 0 0.5em;
  }
}

@media screen and (max-width: 639px) {
  /deep/ .lead {
    max-width: 29.8em;
  }
}
@media screen and (max-width: 479px) {
  .section-title {
    max-width: 4.8em !important;
  }
  .portrait .section-decal {
    max-width: 55vw;
  }
}
@media screen and (max-width: 540px) {
  /deep/ .bird {
    margin: -1.6em 0 0 0.2em;
  }
  .part-lead {
    margin-right: var(--col-padding-adjust);
  }
}
@media screen and (max-width: 390px) {
  /deep/ .bird {
    margin: -1.6em 0 0 0.2em;
  }
}
</style>
